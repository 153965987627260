/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300;400;700&display=swap');
@import "variables";
@import "./assets/sf-ui-display-cufonfonts-webfont/style";

html, body {
  height: 100%;
}

body {
  margin: 0;
  //font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: 'SF UI Display Medium', serif;
  color: $color-white;
}

.w-100 {
  width: 100%;
}

.display-flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-1 {
  flex: 1;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.clickable {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'SF UI Display Medium', serif !important;
}

input, .mat-input-element {
  font-size: 16px !important;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 16px !important;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    font-size: 16px !important;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    font-size: 16px !important;
  }
  &:-moz-placeholder { /* Firefox 18- */
    font-size: 16px !important;
  }
  &::placeholder {
    font-size: 16px !important;
  }
}

.mat-table, .mat-form-field, .mat-typography, .mat-button {
  font-family: 'SF UI Display Medium', serif
}

.text-center {
  text-align: center;
}

.mat-app-background, .mat-table {
  background-color: $color-background;
}

.mt {
  &-5 {
    margin-top: 5px;
  }
  &-10 {
    margin-top: 10px;
  }
}

.mr {
  &-5 {
    margin-right: 5px;
  }
  &-10 {
    margin-right: 10px;
  }
}

.mb {
  &-5 {
    margin-bottom: 5px;
  }
  &-10 {
    margin-bottom: 10px;
  }
}

.ml {
  &-5 {
    margin-left: 5px;
  }
  &-10 {
    margin-left: 10px;
  }
}

//.mat-cell-header-center {
//  .mat-sort-header-container {
//    justify-content: center;
//  }
//}

.mat-cell-header-left {
  padding: 10px !important;
  text-align: left !important;
}

.mat-cell-left {
  padding: 10px !important;
  text-align: left !important;
}

.mat-table {
  background-color: #3C3C3C;
  border-radius: 5px;
  overflow: hidden;
  border-spacing: 1px;

  .mat-sort-header-container {
    padding: 10px;
  }

  th.mat-header-cell {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding-right: 0px !important;
    color: $color-white;
    border-right: 1px solid #525252;
    border-bottom-width: 0;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      border-right: none;
    }
  }

  td.mat-cell {
    border-bottom-width: 0;
    border-right: 1px solid #525252;
    text-align: center;
    color: $color-white;
    border-top: 2px solid $color-background !important;
  }

  tr {
    &.mat-header-row {
      height: 36px;
      background-color: #454545;
    }
    &.mat-row {
      height: 40px;
    }

    td:first-of-type {
      padding-left: 0;
      //border-left: $border-width-default solid $color-white;
    }
    td:last-of-type {
      padding-right: 0;
      border-right: 0;
      //border-right: $border-width-default solid $color-white;
    }
  }

  tr:first-of-type {
    td.mat-cell {
      border-top: none;
    }
  }

  tr:last-of-type {
    td.mat-cell {
      border-bottom: 1px solid #525252;
    }
  }

  tr.highlight {
    td.mat-cell {
      background-color: rgba(42, 124, 224, 0.2);
    }
  }
}


/*.mat-dialog-container {
  .mat-table {
    background-color: #424242;
  }
}*/

.btn {
  font-family: 'SF UI Display Medium', serif;
  font-size: 14px;
  font-weight: bold;
  padding: 12px;
  background-color: $color-background;
  color: $color-white;
  border: none;
  // border: $border-width-default solid $color-white;
  border-radius: 8px;
  cursor: pointer;
  min-width: 100px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:first-of-type) {
    margin-left: 8px;
  }

  &-circle {
    height: 200px;
    min-width: 200px;
    max-width: 200px;
    font-size: 28px;
    border-radius: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-icon {
    padding: 6px;
    min-width: 44px;
  }

  &-sm {
    padding: 8px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $color-background-soft;
    }
  }

  &:disabled {
    opacity: 0.3;
  }

  &-yellow {
    background-color: $color-yellow;

    @media (hover: hover) {
      &:hover {
        background-color: $color-yellow-soft;
      }
    }
  }
  &-buzzer {
    background: radial-gradient(50% 50% at 50% 50%, #FF7B77 0%, #F13E38 100%);

    @media (hover: hover) {
      &:hover {
        background-color: $color-red-soft;
      }
    }

    &-no-hover {
      @media (hover: hover) {
        &:hover {
          background-color: $color-red;
        }
      }
    }
  }
  &-red {
    background-color: $color-red;

    @media (hover: hover) {
      &:hover {
        background-color: $color-red-soft;
      }
    }

    &-no-hover {
      @media (hover: hover) {
        &:hover {
          background-color: $color-red;
        }
      }
    }
  }
  &-blue {
    background-color: $color-blue;

    @media (hover: hover) {
      &:hover {
        background-color: $color-blue-soft;
      }
    }
  }
  &-white {
    background-color: $color-white-darker;
    color: $color-background;

    @media (hover: hover) {
      &:hover {
        background-color: $color-white;
      }
    }
  }
}


hr {
  width: 100%;
  margin: 0;
  border: 1px solid #fff;
  border-left: 0;
  border-right: 0;
}

app-master-session {
  .top-container {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }
}

app-master-session-answers-table {
  .mat-column-answer {
    .mat-chip-list-wrapper {
      justify-content: center;
    }
  }

  .select-mode {
    margin-right: 8px;

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  .demo-button-container {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}

app-master-session-rounds-table {
  td.mat-cell {
    min-width: 50px;
  }
}

.text-red {
  color: #f44336;
}

.text-green {
  color: #4caf50;
}


// REDESIGN GAMES-SESSIONS
mat-dialog-container {
  background-color: $color-background !important;
}



app-game-sessions {
  .mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-radius: 10px !important;
        border: 1px solid #616161 !important;
      }
      .mat-form-field-underline {
        display: none !important;
      }
    }
  }

  .lang-dropdown {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          border-radius: 0px !important;
          border: none !important;
        }
      }
  }
}



mat-icon {
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.disabled {
  pointer-events: none;
}

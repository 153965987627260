$color-background: #2A2A2A;
$color-background-soft: #4d4d4d;
$color-white: #f2f2f2;
$color-white-darker: #d2d2d2;
$color-blue: #2A7CE0;
$color-blue-soft: #548bd0;
$color-red: #e8282b;
$color-red-soft: #ff7578;
$color-yellow: #fba904;
$color-yellow-soft: #fdce6f;
$border-width-default: 2px;

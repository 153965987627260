@use 'sass:map';
@use '~@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette:  map.get($color-config, 'accent');
  $warn-palette:    map.get($color-config, 'warn');
  $is-dark-theme:   map.get($color-config, 'is-dark');

  $my-green-palette:  mat.define-palette(mat.$green-palette, 500);
  $my-red-palette:    mat.define-palette(mat.$red-palette, 500);
  $my-orange-palette: mat.define-palette(mat.$orange-palette, 500);

  .example-class {
    // Read the 500 hue from the primary color palette.
    color: mat.get-color-from-palette($primary-palette, 500);
  }

  .background-color-green {
    background-color: mat.get-color-from-palette($my-green-palette, 500) !important;
  }

  .background-color-red {
    background-color: mat.get-color-from-palette($my-red-palette, 500) !important;
  }

  .background-color-orange {
    background-color: mat.get-color-from-palette($my-orange-palette, 500) !important;
  }

  .background-color-transparent {
    background-color: transparent !important;
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);
  $my-font-family: mat.font-family($typography-config);

  .example-class {
    font-family: $my-font-family;
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
